import Box from '@mui/material/Box';
import {
  Button,
  CircularProgress,
  Icon,
  IconButton,
  Typography,
  circularProgressClasses,
} from '@mui/material';

import CardScreen from './components/CardScreen';
import BottomSheet from './components/BottomSheet';
import { useEffect, useState } from 'react';
import useScreenWidth from './hooks/useScreenWidth';
import { theme } from './theme';
import whatsappIcon from './assets/whatsapp2.png';
import facebookIcon from './assets/facebook.png';
import twitterIcon from './assets/twitter.png';
import { ReactComponent as GameLogo } from './assets/game_logos/shady_logo.svg';

enum GameColor {
  Red,
  Blue,
  Yellow,
  Green,
  Brown,
  Purple,
  Grey,
  Cyan,
  Orange,
  DarkBlue,
  DarkPurple,
  DarkGreen,
}

const colorValues: { [key: number]: string } = {
  [GameColor.Red]: '#c23d3d',
  [GameColor.Blue]: '#316bc8',
  [GameColor.DarkBlue]: '#101d92',
  [GameColor.Yellow]: '#e3ce41',
  [GameColor.Green]: '#56d250',
  [GameColor.DarkGreen]: '#108631',
  [GameColor.Brown]: '#9d4934',
  [GameColor.Purple]: '#c167b3',
  [GameColor.DarkPurple]: '#5a1f78',
  [GameColor.Grey]: '#777777',
  [GameColor.Cyan]: '#63c2e2',
  [GameColor.Orange]: '#ea7348',
};

const levelOpacityValues: { [key: number]: Level } = {
  1: {
    size: 2,
    opacity: 0.21,
  },
  2: {
    size: 2,
    opacity: 0.19,
  },
  3: {
    size: 3,
    opacity: 0.15,
  },
  4: {
    size: 3,
    opacity: 0.13,
  },
  5: {
    size: 4,
    opacity: 0.11,
  },
  6: {
    size: 4,
    opacity: 0.1,
  },
  7: {
    size: 5,
    opacity: 0.07,
  },
  8: {
    size: 5,
    opacity: 0.03,
  },
};

interface GameState {
  score: number;
  size: number;
  activeDot: number;
  color: GameColor;
}

const getInitialState = (): GameState => {
  const level = getLevel(0);
  return {
    score: 0,
    size: level.size,
    activeDot: Math.floor(Math.random() * (level.size * level.size)),
    color: Math.floor((Math.random() * Object.keys(GameColor).length) / 2),
  };
};

interface DotProps {
  color: string;
  overlayOpacity: number;
  active: boolean;
  size: number;
  margin: string;
  onClick(): void;
}

interface Level {
  size: number;
  opacity: number;
}

const getLevel = (score: number): Level => {
  if (score < 2) return levelOpacityValues[1];
  if (score < 4) return levelOpacityValues[2];
  if (score < 9) return levelOpacityValues[3];
  if (score < 13) return levelOpacityValues[4];
  if (score < 18) return levelOpacityValues[5];
  if (score < 25) return levelOpacityValues[6];
  if (score < 30) return levelOpacityValues[7];
  return levelOpacityValues[8];
};

const getDotSize = (screenSize: number, gridSize: number) => {
  const screen = Math.min(screenSize, 400) - 24 * 2;
  const margin = 8;
  return Math.floor((screen - 2 * margin * (gridSize + 1)) / gridSize);
};

const Dot = ({ color, overlayOpacity, active, size, margin, onClick }: DotProps) => {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        position: 'relative',
        margin: margin,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          background: color,
          position: 'relative',
          borderRadius: '100%',
          overflow: 'hidden',
          transition: 'all ease-out 100ms',
          [theme.breakpoints.up('sm')]: {
            ':hover': {
              transform: 'scale(1.1)',
            },
          },

          animation: 'grow 400ms ease-in-out',
          '@keyframes grow': {
            from: {
              width: '10%',
              height: '10%',
            },
            to: {
              width: '100%',
              height: '100%',
            },
          },
        }}
      >
        {active && (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              top: 0,
              bottom: 0,
              background: `rgba(0,0,0,${overlayOpacity || 0})`,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

const timeout = 10;

const Dots = () => {
  const [state, setState] = useState(getInitialState());
  const [isPlaying, setIsPlaying] = useState(true);
  const screenSize = useScreenWidth();
  const [countdown, setCountdown] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  useEffect(() => {
    let interval: any;

    if (timerRunning) {
      interval = setInterval(() => {
        setCountdown((prevTime) => {
          if (prevTime === 0) {
            clearInterval(interval);
            setIsPlaying(false);
            setTimerRunning(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timerRunning]);

  const isActive = (row: number, column: number, size: number, index: number): boolean => {
    const itemIndex = row * size + column;
    return itemIndex === index;
  };

  const handleDotClick = (row: number, column: number) => {
    if (!isPlaying) {
      return;
    }

    const isCorrect = isActive(row, column, state.size, state.activeDot);
    if (!isCorrect) {
      setIsPlaying(false);
      return;
    }

    setCountdown(timeout);
    setTimerRunning(true);

    setState((prev) => {
      const newScore = prev.score + 1;
      const newLevel = getLevel(newScore);

      return {
        ...prev,
        score: newScore,
        activeDot: Math.floor(Math.random() * (newLevel.size * newLevel.size)),
        color: Math.floor((Math.random() * Object.keys(GameColor).length) / 2),
        size: newLevel.size,
      };
    });
  };

  const handleRestart = () => {
    setState(getInitialState());
    setIsPlaying(true);
    setTimerRunning(false);
    setCountdown(0);
  };

  const handleShare = () => {
    setOpenShare(true);
  };

  const handleCopyToClipboard = (message: string) => {
    if (copiedToClipboard) {
      return;
    }

    navigator.clipboard.writeText(message);
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 1000);
  };

  const shareMessage = `I scored ${state.score} on the Shady Dot challenge. Can you beat that? Try it out at ${window.location.href}
  `;

  const shareModal = (
    <>
      <BottomSheet open={openShare} onClose={() => setOpenShare(false)}>
        <Box sx={{ mt: 2, p: 2 }}>
          <Typography variant="h6" mb={2}>
            Share your progress
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="body2"
              textAlign="left"
              sx={{ background: 'rgba(0, 0, 0, 0.05)', borderRadius: 1.5, padding: 2 }}
            >
              {shareMessage}
            </Typography>
            <IconButton sx={{ ml: 1 }} onClick={() => handleCopyToClipboard(shareMessage)}>
              <Icon>{copiedToClipboard ? 'checked_circle' : 'content_copy'}</Icon>
            </IconButton>
          </Box>
          {[
            {
              url: `whatsapp://send?text=${shareMessage}`,
              text: 'Share on WhatsApp',
              iconUrl: whatsappIcon,
            },
            {
              url: `https://www.facebook.com/sharer/sharer.php?u=${window.location.host}`,
              text: 'Share on Facebook',
              iconUrl: facebookIcon,
            },
            {
              url: `https://twitter.com/intent/tweet?text=${shareMessage}`,
              text: 'Share on Twitter',
              iconUrl: twitterIcon,
            },
          ].map((item) => (
            <Box
              component="a"
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              key={item.url}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                color: 'black',
                padding: 1,
                textDecoration: 'none',
                width: '100%',
                margin: 'auto',
                marginTop: 1,
                borderRadius: 1,
                ':hover': {
                  background: 'rgba(0,0,0,0.05)',
                },
              }}
            >
              <Box component="img" src={item.iconUrl} sx={{ width: 40, height: 40 }} mr={2} />
              {item.text}
            </Box>
          ))}
        </Box>
      </BottomSheet>
    </>
  );

  if (!isPlaying) {
    return (
      <CardScreen>
        <GameLogo />
        <Typography variant="h6" mb={0} sx={{ fontWeight: 500, opacity: 0.4 }}>
          Your score
        </Typography>
        <Typography variant="h2" mb={2} sx={{ fontWeight: 600 }}>
          {state.score}
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          gap={2}
        >
          <Button
            sx={{ minWidth: 140 }}
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleRestart}
          >
            <Typography variant="body1" color="inherit" fontWeight={500}>
              Try again
            </Typography>{' '}
            <Icon sx={{ ml: 1 }}>replay</Icon>
          </Button>
          <Button
            sx={{ minWidth: 140 }}
            variant="contained"
            color="secondary"
            disableElevation
            onClick={handleShare}
          >
            <Typography variant="body1" color="inherit" fontWeight={500}>
              Share
            </Typography>
            <Icon sx={{ ml: 1 }}>share</Icon>
          </Button>
        </Box>
        {shareModal}
      </CardScreen>
    );
  }

  return (
    <CardScreen>
      {!timerRunning && (
        <Box mb={1}>
          <GameLogo />
        </Box>
      )}
      {state.score < 1 && (
        <Typography variant="h6" mb={1} sx={{ opacity: 0.4 }}>
          One dot is darker... Can you see it?
        </Typography>
      )}
      {state.score ? (
        <Typography variant="h3" mb={2} sx={{ opacity: 0.4, fontWeight: 500 }}>
          {state.score}
        </Typography>
      ) : (
        <Typography variant="subtitle1" mb={2} sx={{ opacity: 0.4, fontWeight: 500 }}>
          (Tap to start)
        </Typography>
      )}
      {timerRunning && (
        <Box sx={{ position: 'relative', width: 'fit-content', margin: 'auto', mb: 2 }}>
          <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
            }}
            size={50}
            thickness={4}
            value={100}
          />
          <CircularProgress
            sx={{
              opacity: 0.8,
              position: 'absolute',
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            }}
            size={50}
            thickness={4}
            variant="determinate"
            value={100 - (100 / timeout) * countdown}
          />
        </Box>
      )}
      <Box mb={3}>
        {new Array(state.size).fill(null).map((_, rowIndex) => (
          <Box
            key={`${state.score}_${rowIndex}`}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            {new Array(state.size).fill(null).map((_, columnIndex) => (
              <Dot
                key={`${state.score}_${columnIndex}`}
                active={isActive(rowIndex, columnIndex, state.size, state.activeDot)}
                color={colorValues[state.color]}
                overlayOpacity={getLevel(state.score).opacity}
                onClick={() => handleDotClick(rowIndex, columnIndex)}
                margin={state.size === 2 ? '10px' : '6px'}
                size={getDotSize(screenSize, state.size)}
              />
            ))}
          </Box>
        ))}
      </Box>
      {shareModal}
    </CardScreen>
  );
};

export default Dots;
