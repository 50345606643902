import { createBrowserRouter, Navigate } from 'react-router-dom';

import paths from './paths';
import Dots from '../Dots';

const router = createBrowserRouter([
  { path: paths.shadyDot, element: <Dots /> },
  {
    path: paths.shadyDotLegacy,
    element: <Navigate to={paths.shadyDot} />,
  },
  {
    path: '*',
    element: <Navigate to={paths.shadyDot} />,
  },
]);

export default router;
