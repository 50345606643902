import React from 'react';
import { Box, Paper, Slide, useTheme } from '@mui/material';

interface BottomSheetProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const BottomSheet = ({ open, onClose, children }: BottomSheetProps) => {
  const theme = useTheme();
  return (
    <>
      {open && (
        <Box
          onClick={onClose}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 998,
            margin: 'auto',
            background: 'rgba(0, 0, 0, 0.3)',
          }}
        />
      )}
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 999,
            maxWidth: 400,
            maxHeight: '70vh',
            borderRadius: 0,
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            boxShadow: theme.shadows[3],
            margin: 'auto',
            overflowY: 'auto',
          }}
        >
          {children}
        </Paper>
      </Slide>
    </>
  );
};

export default BottomSheet;
