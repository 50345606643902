import { Box, Card, Icon, IconButton, Typography, useTheme } from '@mui/material';
import useScreenWidth from '../hooks/useScreenWidth';
import { ReactComponent as Logo } from '../assets/kai_logo.svg';

interface CardScreenProps {
  children: React.ReactNode;
  title?: string;
  showBack?: boolean;
  onBackClick?(): void;
}

const CardScreen = ({ children, title, showBack, onBackClick }: CardScreenProps) => {
  const theme = useTheme();
  const screenWidth = useScreenWidth();
  return (
    <Box
      sx={{
        [theme.breakpoints.up('sm')]: {
          mt: 3,
        },
      }}
    >
      <Box
        mb={2}
        sx={{
          display: 'none',
          [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'center',
          },
        }}
      >
        <Logo style={{ width: 180 }} />
      </Box>
      <Card
        sx={{
          margin: 'auto',
          padding: 2,
          width: '100%',
          textAlign: 'center',
          position: 'relative',
          borderRadius: 0,
          [theme.breakpoints.down('sm')]: {
            boxShadow: 'none',
            minHeight: '100vh',
          },
          [theme.breakpoints.up('sm')]: {
            borderRadius: 2,
            maxWidth: 500,
            // border: '1px solid rgba(0,0,0,0.2 )',
            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.08), 0px 1px 3px 0px rgba(0,0,0,0.08)',
          },
        }}
      >
        {showBack && (
          <Box sx={{ position: 'absolute', left: 24, top: 24, zIndex: 2 }}>
            <IconButton onClick={onBackClick} color="primary">
              <Icon>arrow_back</Icon>
            </IconButton>
          </Box>
        )}
        {title && (
          <Typography
            sx={{ marginBottom: 2, zIndex: 1, position: 'relative' }}
            fontWeight={700}
            variant={screenWidth < theme.breakpoints.values.sm ? 'h4' : 'h4'}
          >
            {title}
          </Typography>
        )}
        {children}
      </Card>
      {screenWidth < theme.breakpoints.values.sm && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'column',
            pt: 2,
          }}
        >
          <Typography variant="subtitle2" mb={1} sx={{ opacity: 0.4 }}>
            Powered by
          </Typography>
          <Box component="a" href={window.location.hostname} mb={2}>
            <Logo width={180} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CardScreen;
