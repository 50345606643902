import { createTheme } from '@mui/material/styles';

enum Color {
  Black = '#333',
  White = '#fff',
  Orange = '#eb7d34',
  Green = '#34be2d',
  GreyLight = '#efefef',
  Pink = '#ff00bf',
}

export const theme = createTheme({
  palette: {
    background: {
      // Overridden in index.css due to media queries not working for theme.palette
      default: Color.GreyLight,
    },
    primary: {
      main: Color.Black,
    },
    secondary: {
      main: Color.Pink,
    },
    common: {
      white: Color.White,
    },
    success: {
      main: Color.Green,
    }
  },
  typography: {
    allVariants: {
      color: Color.Black,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: '-6px',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          minWidth: 64,
          color: Color.Black,
          opacity: 0.6,
          '::selection': {
            opacity: 1,
          },
        },
        iconOnly: {
          opacity: 0.2,
        },
        label: {
          fontSize: '0.8rem',
          opacity: 1,
          '::selection': {
            fontSize: '0.8rem',
            fontWeight: 600,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          padding: '12px 22px',
          borderRadius: 48,
          textTransform: 'none',
          fontWeight: 600,
        },
        text: {
          padding: '12px 22px',
          borderRadius: 48,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
  },
});
